import React, { useState } from "react";

import { Modal, Form, Alert } from "react-bootstrap";
import CustomButton from "./CustomButton";

const FeedbackModal = ({ show, close, sendData }) => {

    const [nameIsValid, setNameIsValid] = useState(false)
    const [phoneIsValid, setPhoneIsValid] = useState(false)
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [phoneIsInvalid, setPhoneIsInvalid] = useState(false)
    const [emailIsInvalid, setEmailIsInvalid] = useState(false)
    const [consentChecked, setConsentChecked] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [feedback, setFeedback] = useState({
        sender_name: "",
        phone: "",
        email: "",
        comment: "",
        selected_option: "phone"
    })
    const handleChange = (e) => {
        setFeedback(prevFeedback => ({
            ...prevFeedback,
            selected_option: e.target.value
        }))
    };
    const validateName = (name) => {
        return name.length
    }
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        return regex.test(email)
    }
    const validatePhone = (number) => {
        const regex = /^(\+7|8|7)\d{10}$/

        return regex.test(number)
    }
    const setSenderName = (event) => {
        let name = event.target.value
        setFeedback(prevFeedback => ({
            ...prevFeedback,
            sender_name: name
        }))
        let nameIsValid = validateName(name)
        setNameIsValid(nameIsValid)
    }
    const setPhone = (event) => {
        let phone = event.target.value
        setFeedback(prevFeedback => ({
            ...prevFeedback,
            phone: phone
        }))
        let phoneIsValid = validatePhone(phone)
        setPhoneIsValid(phoneIsValid)
        if (phoneIsValid) {
            setPhoneIsInvalid(false)
        }
    }
    const setEmail = (event) => {
        let email = event.target.value
        setFeedback(prevFeedback => ({
            ...prevFeedback,
            email: email
        }))
        let emailIsValid = validateEmail(email)
        setEmailIsValid(emailIsValid)
        if (emailIsValid) {
            setEmailIsInvalid(false)
        }
    }
    const validate = () => {
        let alertMsg = ""
        if (feedback.email.length && !validateEmail(feedback.email)) {
            alertMsg = 'Укажите, пожалуйста, корректный адрес электронной почты'
            setEmailIsInvalid(true)
        }
        if (feedback.phone.length && !validatePhone(feedback.phone)) {
            alertMsg = 'Укажите, пожалуйста, корректный номер телефона'
            setPhoneIsInvalid(true)
        }
        setAlertText(alertMsg)
        if (alertMsg.length) return

        let contact_data = feedback.phone
        if (contact_data.length) contact_data += "\n"
        contact_data += feedback.email
        sendData(feedback)
        close()
    }
    return <Modal
        style={{ minWidth: "200px" }}
        show={show}
        onHide={close}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton className="feedback-header-visible">
            <Modal.Title id="contained-modal-title-vcenter" className="modal-title-centered">
                Обратная связь
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Modal.Title
                className="feedback-title-visible mb-2 modal-title-centered">
                Обратная связь
            </Modal.Title>
            {!!alertText &&
                <Alert variant="danger">
                    {alertText}
                </Alert>
            }
            <Form>
                <Form.Group className="mb-1">
                    <Form.Label>Имя:</Form.Label>
                    <Form.Control
                        isValid={nameIsValid}
                        value={feedback.sender_name}
                        onChange={setSenderName}
                        type="text"
                        placeholder="Иван Иванович" />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Контактные данные:</Form.Label>
                    <Form.Control
                        isValid={phoneIsValid}
                        isInvalid={phoneIsInvalid}
                        onChange={setPhone}
                        value={feedback.phone}
                        type="text"
                        placeholder="+79999999999" />
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Control
                        isValid={emailIsValid}
                        isInvalid={emailIsInvalid}
                        onChange={setEmail}
                        value={feedback.email}
                        type="email"
                        placeholder="user@example.com" />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Предпочтительный способ связи:</Form.Label>
                    <Form.Check
                        type="radio"
                        label="Телефон"
                        name="radioOptions"
                        id="radioPhone"
                        value="phone"
                        checked={feedback.selected_option === 'phone'}
                        onChange={handleChange}
                    />
                    <Form.Check
                        type="radio"
                        label="Почта"
                        name="radioOptions"
                        id="radioEmail"
                        value="email"
                        checked={feedback.selected_option === 'email'}
                        onChange={handleChange}
                    />
                    <Form.Check
                        type="radio"
                        label="Telegram"
                        name="radioOptions"
                        id="radioTelegram"
                        value="telegram"
                        checked={feedback.selected_option === 'telegram'}
                        onChange={handleChange}
                    />
                    <Form.Check
                        type="radio"
                        label="WhatsApp"
                        name="radioOptions"
                        id="radioWatsapp"
                        value="whatsapp"
                        checked={feedback.selected_option === 'whatsapp'}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox" className="mt-3 checkbox-label">
                    <Form.Check
                        type="checkbox"
                        label="Я согласен на обработку персональных данных"
                        checked={consentChecked}
                        onChange={() => setConsentChecked(prevConsentChecked => !prevConsentChecked)}
                    />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer className="model-footer">
            <CustomButton className="popup-custom-button" text="Отправить"
                disabled={!(phoneIsValid || emailIsValid) || !nameIsValid || !consentChecked}
                clickHandler={() => {
                    validate()
                }} />
            <CustomButton className="popup-custom-button" text="Закрыть" clickHandler={close} />
        </Modal.Footer>
    </Modal>
}

export default FeedbackModal
