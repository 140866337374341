import React, { useState } from "react";
import "./styles/FooterBody.css"
import ScrollTrigger from "react-scroll-trigger";
import { Container, Row, Col } from "react-bootstrap";
import CustomButton from "./UI/CustomButton";
import AnimatedArrow from "./UI/AnimatedArrow";

export const FooterBody = () => {
    const [show, setShow] = useState(false)
    return (
        <div className="footer-container">
            <div className="limiter">
                <Container className="footer-subcontainer">
                    <Row className="footer-button-row">
                        <h1
                            style={{ color: "white" }}
                            className={`footer-title${show ? " show" : ""}`}
                        >
                            Подписывайся на наш <br className="br-visible" /> блог в телеграмм!
                        </h1>
                        <ScrollTrigger onEnter={() => setShow(true)} />
                    </Row>
                    <Row className="footer-row">
                        <p className="footer-subtitle" style={{ color: "white" }}>
                            Здесь наши проекты, секреты ухода <br className="br-visible" /> за цветочками и жизнь компании
                        </p>
                    </Row>
                    <Row style={{ position: "relative" }} className="align-items-center footer-row">
                        <Col md="auto" xs="auto">
                            <CustomButton
                                text="Вступить в сообщество"
                                clickHandler={() => {
                                    window.location.href = 'https://t.me/lyrataspace';
                                }}
                            />
                            <AnimatedArrow className="footer-animated-arrow" style={{ position: "absolute" }} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}