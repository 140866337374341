import React, { useState } from "react";
import "./styles/FooterAdditional.css"
import Modal from 'react-bootstrap/Modal';
import CustomButton from "./UI/CustomButton";
import { ConfidentialityPolice } from "./ConfidentialityPolice";

export const FooterAdditional = () => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <div className="footer-additional-container">
            <div>
                <div className="info-container">
                    <div>
                        <p className="info-text">Россия, Москва</p>
                        <a href="TEL:+79773756100" className="info-text">Тел: +79773756100</a>
                    </div>
                    <div>
                        <p className="info-text">Пн-пт с 10:00 до 19:00</p>
                        <a href="mailto:work@lyrataspace.ru" className="info-text">E-mail: work@lyrataspace.ru</a>
                    </div>
                </div>

            </div>
            <div className="white-line" />
            <p onClick={() => setModalShow(true)} className="confidentiality">Политика конфиденциальности</p>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Политика конфиденциальности
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ConfidentialityPolice />
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton text="Закрыть" clickHandler={() => setModalShow(false)} />
                </Modal.Footer>
            </Modal>
        </div>
    )
}