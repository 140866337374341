import React from "react";
import { Container } from "react-bootstrap";
import "./styles/Body.css";
import { Preview } from "./Preview";
import { Advantages } from "./Advantages";
import { OurService } from "./OurService";
import { StagesOfWork } from "./StagesOfWork";
import { OurProjects } from "./OurProjects";
import { Solutions } from "./Solutions";

export const Body = () => {
  return (
    <>
      <Container className="body-container">
        <div className="body-limiter">
          <Preview />
          <div className="body-separator" />
          <Advantages />
          <OurService />
        </div>
      </Container>
      <StagesOfWork />
      <Container className="body-container">
        <div className="body-limiter">
          <OurProjects />
        </div>
      </Container>
      <Solutions />
      <div style={{ height: "100px" }} />
    </>
  );
};
