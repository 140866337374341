import { Col, Row } from "react-bootstrap";
import "./styles/Advantages.css";
import { SheetIcon } from "./UI/SheetIcon";
import React from "react";

const Advantage = ({ className, title, description }) => {
  return (
    <Col sm={12} lg={6} className={`advantage-col ${className}`}>
      <div className="advantage-container">
        <SheetIcon className="sheet-icon" />
        <div className="advantage-content">
          <h2 className="advantage-title">{title}</h2>
          <p className="advantage-description">{description}</p>
        </div>
      </div>
    </Col>
  );
};

export const Advantages = () => {
  const advanatges = [
    {
      title: "Индивидуальный подход",
      description:
        "Делаем проект и коммерческое предложение исходя из ваших предпочтений и условий.",
    },
    {
      title: "Опытные сотрудники",
      description:
        "80% сотрудников работают в этой\nсфере более 7 лет и имеют высшее профильное образование.",
    },
    {
      title: "Проверенные поставщики",
      description:
        "Работаем напрямую с питомниками и поставщиками из Голландии, Германии, Бельгии, Турции, России, Польши.",
    },
    {
      title: "Гарантия на растения",
      description:
        "На наши растения мы даем год гарантии. При потере декоративности или гибели растения, меняем бесплатно!",
    },
    {
      title: "Комплекс услуг",
      description:
        "Мы решаем проблему выбора подрядчика по уходу за вашим садом и внутренним озеленением. Мы делаем и то, и то!",
    },
    {
      title: "Соблюдение сроков",
      description:
        "Мы ценим Ваше время и делаем проекты в оговоренный срок. Наши визуализации совпадают с реальностью на 90%.",
    },
  ];
  return (
    <>
      <Row>
        <Col sm={12} className="advantages-title-col">
          <h1 className="advantages-title">
            Наши <span style={{ color: "#5e703d" }}>преимущества</span>
          </h1>
        </Col>
      </Row>
      <Row className="advanatges-row">
        {advanatges.map((advantage, index) => {
          return (
            <Advantage
              key={advantage.title}
              title={advantage.title}
              description={advantage.description}
              className={(index + 1) % 2 === 0 ? "advantage-col-right" : ""}
            />
          );
        })}
      </Row>
    </>
  );
};
