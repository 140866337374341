import React from "react";

export const SheetIcon = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <circle cx="40" cy="40" r="39.5" fill="#5E703D" stroke="#5E703D" />
      <path
        d="M16 57.9352C19.3333 51.1018 30.4 37.2352 48 36.4352"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23 47.9352C28 35.9352 43.4 16.8352 65 36.4352C56.5 35.9352 44 57.4352 23 47.9352Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
