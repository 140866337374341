export const Images = {
  HEADER_BG: require("./images/headerbg.webp"),
  LOGO: require("./images/logo.webp"),
  ANIMATED_ARROW: require("./images/animated_arrow.svg"),
  BODY_TITLE: require("./images/body_title.webp"),
  POT_WITH_PLANT: require("./images/potwithplant.png"),
};

export const Projects = {
  PROJECT1: require("./images/1.jpg"),
  PROJECT2: require("./images/2.jpg"),
  PROJECT3: require("./images/3.jpg"),
  PROJECT4: require("./images/4.jpg"),
};

export const SolutionPackages = {
  LITE: require("./images/solution_lite.png"),
  STANDART: require("./images/solution_standart.png"),
  PREMIUM: require("./images/solution_premium.png")
}