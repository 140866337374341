import React, { useEffect, useState } from "react";
import "./styles/Header.css";
import { Container, Row, Col, Modal } from "react-bootstrap";
import CustomButton from "./UI/CustomButton";
import AnimatedArrow from "./UI/AnimatedArrow";
import FeedbackModal from "./UI/FeedbackModal";
import { Success } from "./UI/Success";
import { Error } from "./UI/Error";

export const Header = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [popupMsg, setPopupMsg] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 1000);
  }, []);

  const sendData = (feedback) => {
    fetch('https://cyberblvde.paradiselocal.club:8888/api/contact', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'fa5590659a90ac3ef7aea36d07923ce0'
      },
      body: JSON.stringify(feedback)
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok: ' + response.statusText);
      }
      return response.json();
    }).then(data => {
      if (data?.message === "Success") {
        setPopupMsg({
          head: "Заявка принята!",
          body: "Ваша заявка успешно отправлена и находится в обработке. Мы свяжемся с вами в ближайшее время! Спасибо, что выбрали нас.",
          success: true
        })
      }
    }).catch(error => {
      setPopupMsg(
        {
          head: "Не удалось отправить",
          body: "К сожалению, не удалось отправить ваши контактные данные. Пожалуйста, попробуйте ещё раз через некоторое время или свяжитесь с нами напрямую, используя контактную информацию на нашем сайте. Мы всегда готовы помочь!",
          success: false
        })

    });
  }

  return (
    <Container className="header-container">
      <Modal show={popupMsg} onHide={() => setPopupMsg(null)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {popupMsg?.head}
            {popupMsg?.success ?
              <Success className="header-icons" /> :
              <Error className="header-icons" />}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {popupMsg?.body}
        </Modal.Body>
        <Modal.Footer>
          <CustomButton className="popup-custom-button" text="Закрыть" clickHandler={() => setPopupMsg(null)} />
        </Modal.Footer>
      </Modal>
      <Row>
        <Col xs={12} lg="auto" className="col-title">
          <h1
            style={{ color: "white" }}
            className={`header-title ${isVisible ? "show" : ""}`}
          >
            Пространство <br className="d-block d-sm-none" /> зеленого{" "}
            <br className="d-none d-sm-block" /> дизайна
            <br className="d-block d-sm-none" /> Lyrata Space
          </h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg="auto">
          <p className="header-subtitle" style={{ color: "white" }}>
            Профессиональное <br className="d-block d-sm-none" /> озеленение
            офисов под ключ
          </p>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md="auto" xs="auto">
          <CustomButton
            className="header-button"
            text="Связаться с нами"
            clickHandler={() => setModalShow(true)}
          />
          {modalShow && <FeedbackModal
            show={modalShow}
            close={() => setModalShow(false)}
            sendData={sendData}
          />}
        </Col>
        <Col xs={2}>
          <AnimatedArrow className="header-animated-arrow" />
        </Col>
      </Row>
    </Container>
  );
};
