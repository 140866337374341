import React from "react";
import { FooterBody } from "./FooterBody";
import { FooterAdditional } from "./FooterAdditional";

export const Footer = () => {
    return (
        <>
            <FooterBody />
            <FooterAdditional />
        </>
    )
}