import React from "react";

export const Error = ({ className }) => {
    return (
        <svg className={`${className}`} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3864_205)">
                <path d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#D75A4A" />
                <path d="M16 34L25 25L34 16" stroke="white" strokeWidth="2" strokeMiterlimit={"10"} strokeLinecap="round" />
                <path d="M16 16L25 25L34 34" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_3864_205">
                    <rect width="50" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}