import { Container, Navbar, Nav, NavItem } from "react-bootstrap";
import "./styles/Navibar.css";
import { Images } from "../Assets";
import React, { useContext } from "react";
import RefContext from "../hooks/RefContext";

export const Navibar = () => {
  const {
    previewRef,
    serviceRef,
    projectRef,
    scrollToRef
  } = useContext(RefContext);

  return (
    <Navbar className="my-navbar" expand="lg" variant="dark">
      <Container className="d-flex align-items-start navbar-container">
        <Navbar.Brand>
          <img alt="logo" className="logo" src={Images.LOGO} />
        </Navbar.Brand>
        <Nav className="d-none d-lg-flex ms-auto">
          <NavItem>
            <Nav.Link onClick={scrollToRef(previewRef)}>О нас</Nav.Link>
          </NavItem>
          <NavItem>
            <Nav.Link onClick={scrollToRef(serviceRef)}>Услуги</Nav.Link>
          </NavItem>
          <NavItem>
            <Nav.Link onClick={scrollToRef(projectRef)}>Проект</Nav.Link>
          </NavItem>
          <NavItem>
            <Nav.Link href="//wa.me/+79773756100">Консультация</Nav.Link>
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
  );
};
