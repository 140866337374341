import React, { useState, useContext, useEffect } from "react";
import HrLine from "./HrLine";
import "./styles/DrawerButton.css";
import { Container, Offcanvas } from "react-bootstrap";
import { Images as Imgs } from "../../Assets";
import { TelegramIcon } from "./TelegramIcon";
import { WatsappIcon } from "./WatsappIcon";
import RefContext from "../../hooks/RefContext";

function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  outer.style.msOverflowStyle = 'scrollbar';
  document.body.appendChild(outer);
  const inner = document.createElement('div');
  outer.appendChild(inner);
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
  outer.parentNode.removeChild(outer);
  return scrollbarWidth;
}
const scrollbarWidth = getScrollbarWidth()

const CustomLink = ({ action, text, hide }) => {
  const clickHandler = (e) => {
    action(e)
    hide()
  }
  return (
    <div onClick={clickHandler} className="offcanvas-div">
      <p className="offcanvas-link">
        {text}
      </p>
    </div>
  );
};

export const DrawerButton = ({ className }) => {
  const [show, setShow] = useState(false);
  const [buttonMargin, setButtonMargin] = useState(false)
  const [buttonVisible, setButtonVisible] = useState(false)
  const {
    previewRef,
    serviceRef,
    projectRef,
    imgViewerOpen,
    scrollToRef
  } = useContext(RefContext);

  const handleClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setButtonVisible(true);
      } else {
        setButtonVisible(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <div style={{
        marginRight: ((buttonMargin || imgViewerOpen)
          && !/Mobi|Android|iPhone/i.test(navigator.userAgent) ? scrollbarWidth : 0)
      }}
        className={`drawer-button${(className ? className : "")
          + (buttonVisible ?
            " drawer-button-visible" : "")}`} onClick={handleClick}>
        <HrLine />
        <HrLine />
        <HrLine />
      </div >
      <Offcanvas
        show={show}
        onHide={handleClick}
        className="drawer"
        placement="end"
        onEnter={() => setButtonMargin(true)}
        onExited={() => setButtonMargin(false)}
      >
        <Container>
          <Offcanvas.Header closeButton closeVariant="white">
            <div>
              <img src={Imgs.LOGO} alt="lyrata-space" className="lyrata-logo" />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ display: "flex", flexDirection: "column" }}>
            <Container className="offcanvas-container">
              <CustomLink text="О нас" action={scrollToRef(previewRef)} hide={handleClick} />
              <CustomLink text={"Услуги"} action={scrollToRef(serviceRef)} hide={handleClick} />
              <CustomLink text={"Проект"} action={scrollToRef(projectRef)} hide={handleClick} />
              <CustomLink text={"Консультация"} action={() => {
                window.location.href = "//wa.me/+79773756100"
              }} hide={handleClick} />
            </Container>
          </Offcanvas.Body>
        </Container>
        <Container
          style={{ display: "flex", marginTop: "auto", marginBottom: "50px" }}
        >
          <Container>
            <TelegramIcon
              className="drawer-icon-size"
              clickHandler={() => {
                window.location.href = "https://t.me/lyrataspace";
              }}
            />
            <WatsappIcon
              className="drawer-icon-size"
              style={{ marginLeft: "10px" }}
              clickHandler={(e) => {
                window.location.href = "//wa.me/+79773756100";
              }}
            />
          </Container>
        </Container>
      </Offcanvas>
    </>
  );
};
