import React from "react"
import { Container } from "react-bootstrap"
import "./styles/Solutions.css"

import { useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { SolutionPackages } from "../Assets";
import { Arrow } from "./UI/Arrow";

function ControlledCarousel() {
    const solutions = [
        {
            src: SolutionPackages.LITE,
            title: "LITE",
            description: "Для быстрого озеленения небольшого офисного пространства. Универсальный цвет кашпо и самые неприхотливые растения.",
            plants: ["Фикус эластика", "Замиокулькас", "Аглаонема Сильвер бей", "Аглаонема Мария", "Сансевиерия лауренти"],
            price: "35 000"
        },
        {
            src: SolutionPackages.STANDART,
            title: "STANDART",
            description: "Для озеленения классического офисного пространства. Возможны другие цвета кашпо.",
            plants: ["Хризолидокарпус", "Аглаонема", "Сильвер бей", "Рапис высокий", "Драцена", "Эпипермнум", "Хамедорея", "Замиокулькас"],
            price: "60 000"
        },
        {
            src: SolutionPackages.PREMIUM,
            title: "PREMIUM",
            description: "Для создания тропиков в офисе. Экзотические растения и кашпо, имитирующие бетон.",
            plants: ["Юкка", "Ховея", "Спатифиллум", "Нолина", "Стрелиция", "Агава", "Алоэ"],
            price: "85 000"
        }
    ]

    const carouselRef = useRef(null);

    const handlePrev = () => {
        carouselRef.current.prev();
    };

    const handleNext = () => {
        carouselRef.current.next();
    };

    return (
        <Carousel
            ref={carouselRef}
            controls={false}
            indicators={false}
            interval={null}
            wrap={true}
            touch={false}
        >
            {solutions.map(solution =>
                <Carousel.Item key={solution.title}>
                    <Container className="body-container">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="body-limiter solution-container">
                                <img
                                    alt={solution.title}
                                    className="solution-img"
                                    src={solution.src}
                                />
                                <div className="solution-detailed-container">
                                    <div className="solution-detailed">
                                        <h2 className="solution-subtitle">Комплект{" "}
                                            <span style={{ color: "#5e703d" }}>
                                                {solution.title}
                                            </span>
                                        </h2>
                                        <p className="solution-text solution-separator">{solution.description}</p>
                                        <p className="solution-text solution-separator">Включает {solution.plants.length} растений:</p>
                                        <div className="solution-separator">
                                            {solution.plants.map(plant =>
                                                <div className="solution-plant-item" key={plant}>
                                                    <div className="solution-round" />
                                                    <p className="solution-text">{plant}</p>
                                                </div>)}
                                        </div>
                                        <p className="solution-text solution-price">
                                            <b>Цена от</b> {solution.price} руб.
                                        </p>
                                        <div className="solution-pagination">
                                            <div onClick={handlePrev} className="solution-arrow">
                                                <Arrow />
                                            </div>
                                            <div onClick={handleNext} className="solution-arrow right-arrow">
                                                <Arrow />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Carousel.Item>)}
        </Carousel>
    );
}

export default ControlledCarousel;

export const Solutions = () => {
    return (
        <div>
            <Container className="body-container">
                <div
                    className="body-limiter solutions-title" style={{ width: "100%" }}>
                    <h1>
                        Готовые <span style={{ color: "#5e703d" }}>решения</span>
                    </h1>
                </div>
            </Container>
            <ControlledCarousel />
        </div>
    )
}