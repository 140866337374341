import "./styles/OurProjects.css";
import { Row } from "react-bootstrap";
import { Projects } from "../Assets";
import FsLightbox from "fslightbox-react";
import React, { useContext, useState } from "react";
import RefContext from "../hooks/RefContext";

const ProjectItem = ({ image, index, click }) => {
  return (
    <div
      onClick={() => click(index)}
      className={`project-container ${(index + 1) % 2 === 0 ?
        "project-lmg" : "project-rmg"
        }`}
    >
      <img className={"project-img"} src={image.src} alt="img" />
      <div className="project-title-div">
        <p className="project-title">{image.title}</p>
      </div>
    </div>
  );
};

export const OurProjects = () => {
  const [toggler, setToggler] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const images = [
    { src: Projects.PROJECT1, title: "Композиции на ресепшен" },
    { src: Projects.PROJECT2, title: "Озеленение офиса на Маяковской" },
    { src: Projects.PROJECT3, title: "Озеленение офиса на Парке Победы" },
    { src: Projects.PROJECT4, title: "Сервисный уход за садом" },
  ];

  const { projectRef, setImgViewerOpen } = useContext(RefContext);

  const pickImage = (index) => {
    setImgIndex(index);
    setToggler(!toggler);
  };

  const onCloseHandler = () => {
    setImgViewerOpen(false)
    setImgIndex(0)
  }

  const onOpenHandler = () => {
    setImgViewerOpen(true)
  }

  return (
    <>
      <Row ref={projectRef}>
        <h1 className="projects-title">
          Наши реализованные <span style={{ color: "#5e703d" }}>проекты</span>
        </h1>
      </Row>
      <Row className="projects-list-row ">
        {images.map((image, index) => (
          <ProjectItem
            key={image.title}
            image={image}
            index={index}
            click={pickImage}
          />
        ))}
      </Row>
      <FsLightbox
        toggler={toggler}
        exitFullscreenOnClose={true}
        sources={images.map((img) => img.src)}
        slide={imgIndex + 1}
        onClose={onCloseHandler}
        onOpen={onOpenHandler}
      />
    </>
  );
};
