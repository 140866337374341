import React from "react";
import "./styles/CustomButton.css";

const CustomButton = ({ text, clickHandler, className, disabled = false }) => {
  return (
    <button
      disabled={disabled}
      className={`custom-button${
        className ? " " + className : ""}${
          disabled ? " disabled-custom-button" : ""}`}
      onClick={() => clickHandler()}
    >
      {text}
    </button>
  );
};

export default CustomButton;
