import "./components/Header";
import { Head } from "./components/Head";
import React, { useRef, useState } from "react";
import { DrawerButton } from "./components/UI/DrawerButton";
import { Body } from "./components/Body";
import "./styles/App.css";
import { Footer } from "./components/Footer";
import RefContext from "./hooks/RefContext";

// xs (для экранов меньше 576px)
// sm (для экранов равных или больше 576px)
// md (для экранов равных или больше 768px)
// lg (для экранов равных или больше 992px)
// xl (для экранов равных или больше 1200px)
// xxl (для экранов равных или больше 1400px)

const App = () => {
  const previewRef = useRef(null)
  const serviceRef = useRef(null)
  const projectRef = useRef(null)

  const [imgViewerOpen, setImgViewerOpen] = useState(false)

  const scrollToRef = (ref) => (event) => {
    event.preventDefault();
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <RefContext.Provider
        value={{
          previewRef: previewRef,
          serviceRef: serviceRef,
          projectRef: projectRef,
          imgViewerOpen: imgViewerOpen,
          setImgViewerOpen: setImgViewerOpen,
          scrollToRef: scrollToRef
        }}
      >
        <DrawerButton />
        <Head />
        <Body />
        <Footer />
      </RefContext.Provider>
    </>
  );
}

export default App;
