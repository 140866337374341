import "./styles/StagesOfWork.css";
import { Container, Row, Col } from "react-bootstrap";
import CustomButton from "./UI/CustomButton";
import React from "react";
import { Images } from "../Assets";

const StagesItem = ({ title, lineVisible }) => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex" }}>
        <div className="round-item" />
        <p className="stages-item-text">{title}</p>
      </div>
      {lineVisible && (
        <div className="vertical-line-container">
          <div className="vertical-line" />
        </div>
      )}
    </div>
  );
};

export const StagesOfWork = () => {
  const stages = [
    "Получение заявки",
    "Выезд специалиста, составление чек листа",
    "Подготовка коммерческого предложения и визуализации",
    "Согласование сметы",
    "Реализация проекта",
    "Сервисный уход",
  ];

  return (
    <div className="stages-container">
      <Container className="body-container" style={{ height: "100%" }}>
        <div
          className="body-limiter"
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Row className="stages-title-row">
            <h1 className="stages-title">
              Этапы работы по
              <br />
              озеленению интерьеров:
            </h1>
          </Row>
          <div className="stages-separator" />
          <Row>
            {stages.map((stage, index) => (
              <StagesItem
                key={stage}
                title={stage}
                lineVisible={index < stages.length - 1}
              />
            ))}
          </Row>
          <Row style={{ zIndex: "1" }}>
            <Col>
              <CustomButton
                className="stages-other-button"
                text="Получить консультацию"
                clickHandler={() => {
                  window.location.href = "//wa.me/+79773756100"
                }}
              />
            </Col>
          </Row>
          <div className="stages-img-div-container">
            <div className="stages-img-div">
              <img className="stages-img" src={Images.POT_WITH_PLANT} alt="plant" />
              <CustomButton
                className="stages-cbutton"
                text="Получить консультацию"
                clickHandler={() => {
                  window.location.href = "//wa.me/+79773756100"
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
