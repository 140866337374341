import { Col, Row } from "react-bootstrap";
import "./styles/Preview.css";
import React, { useContext } from "react";
import { TypeAnimation } from "react-type-animation";
import { HrSeparator } from "./UI/HrSeparator";
import { Images } from "../Assets";
import ScrollTrigger from "react-scroll-trigger";
import { useState } from "react";
import RefContext from "../hooks/RefContext";

const Description = () => {
  const [showFirst, setShowFirst] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [showThird, setShowThird] = useState(false);
  return (
    <div className="description-container">
      <ScrollTrigger onEnter={() => setShowFirst(true)} />
      <p className={`description ${showFirst ? "show" : ""}`}>
        Мы команда специалистов, объединённых идеей, что правильное Озеленение
        творит невероятные чудеса абсолютно в любом пространстве.
      </p>
      <ScrollTrigger onEnter={() => setShowSecond(true)} />
      <p className={`description ${showSecond ? "show" : ""}`}>
        Наша команда включает в себя профессиональных озеленителей, биологов,
        дендрологов, флористов, фитопатологов, фитодизайнеров и декораторов.
      </p>
      <ScrollTrigger onEnter={() => setShowThird(true)} />
      <p className={`description ${showThird ? "show" : ""}`}>
        В каждый проект мы вкладываем частичку своей души и безупречного вкуса.
        Работаем с любыми задачами, с любыми бюджетами и сроками, но всегда
        гарантируем высокое качество.
      </p>
    </div>
  );
};

export const Preview = () => {
  const [show, setShow] = useState(false);
  const { previewRef } = useContext(RefContext);

  return (
    <>
      <Row >
        <Col xs={12} lg={6} className="type-col">
          <div className="type-animation-container">
            {show && (
              <TypeAnimation
                sequence={["Работаем качественно,\nбережно, с душой."]}
                wrapper="span"
                speed={50}
                className="type-animation"
                cursor={true}
              />
            )}
            <ScrollTrigger
              onEnter={() => {
                setShow(true);
              }}
            // onExit={() => {
            //   setShow(false);
            // }}
            />
            <HrSeparator className="hr-separator" />
          </div>
        </Col>
        <Col md={12} lg={6} className="title-col" ref={previewRef}>
          <p className="title">
            Пространство{" "}
            <span style={{ color: "#5e703d" }}>
              зеленого <br className="d-block d-md-none" />
            </span>
            <br className="d-none d-sm-none d-md-block" />
            дизайна - Lyrata Space
          </p>
        </Col>
        <Col md={12} lg={6} className="img-col">
          <img
            alt="Фитодизайн"
            className="body-title"
            src={Images.BODY_TITLE}
          />
        </Col>
        <Col xs={12} lg={6} className="description-col">
          <Description />
        </Col>
      </Row>
    </>
  );
};
