import { createContext } from "react";

const RefContext = createContext({
    previewRef: null,
    serviceRef: null,
    projectRef: null,
    imgViewerOpen: false,
    setImgViewerOpen: () => { },
    scrollToRef: () => { }
})

export default RefContext;