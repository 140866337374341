import { Navibar } from "./Navibar";
import "./styles/Head.css";
import "./Header";
import React from "react";
import { Header } from "./Header";

// xs (для экранов меньше 576px)
// sm (для экранов равных или больше 576px)
// md (для экранов равных или больше 768px)
// lg (для экранов равных или больше 992px)
// xl (для экранов равных или больше 1200px)
// xxl (для экранов равных или больше 1400px)

export const Head = () => {
  return (
    <>
      <div className="head-container">
        <div className="limiter">
          <Navibar />
          <Header />
        </div>
      </div>
      <div className="separator-div"></div>
    </>
  );
};
