import React from "react";
import "./styles/AnimatedArrow.css";

const AnimatedArrow = ({ style, className }) => {
  return (
    <div className={`animated-arrow${className ? " " + className : ""}`} style={{ ...style }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-arrow"
        viewBox="0 0 142 111"
        fill="none"
      >
        <path d="M2 106C41.6667 101.5 124 86 141.5 1" stroke="#FEF9E5" />
        <path
          d="M10 92C10 95 8.5 102 2.5 106C6.16667 106 14.2 106.9 17 110.5"
          stroke="#FEF9E5"
        />
      </svg>
    </div>
  );
};

export default AnimatedArrow;
