import { Row, Col } from "react-bootstrap";
import "./styles/OurService.css";
import React, { useContext } from "react";
import RefContext from "../hooks/RefContext";

const services = [
  "Интерьерное озеленение живыми, искусственными и стабилизированными растениями",
  "Ландшафтные работы: от проекта до реализации",
  "Праздничный/сезонный декор",
  "Флористика и сухоцветы",
  "Разовый выезд специалиста",
  "Сервисный уход",
];

const Service = ({ title, index }) => {
  return (
    <div className={`service-container${index + 1 === services.length ?
      " service-bottom-line" : ""}`}>
      <div className="service-hr-line" />
      <div className="service-item">
        <h2 className="service-index">{"0" + (index + 1)}</h2>
        <p className="service-text">{title}</p>
      </div>
    </div>
  );
};

export const OurService = () => {
  const { serviceRef } = useContext(RefContext);
  return (
    <>
      <Row>
        <Col className="service-title-col" ref={serviceRef}>
          <div className="service-title">
            <h1>
              Наши <span style={{ color: "#5e703d" }}>услуги</span>
            </h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {services.map((title, index) => {
            return <Service key={title} title={title} index={index} />;
          })}
        </Col>
      </Row>
    </>
  );
};
